// pages/404.js
import React from 'react'

const Custom404 = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>404 - Page Not Found</h1>
    </div>
  )
}

export default Custom404
